import React, { useCallback, useLayoutEffect, useState } from 'react'
import './App.scss'
import Routes from './routes/Routes'
import colorSet from './configs/colorSet.json'
import cssVars from 'css-vars-ponyfill'
import classNames from 'classnames'


function App() {
	const [isMobile, setIsMobile] = useState(false)
	const [partnerName, setPartnerName] = useState(localStorage.getItem('partnerName') || 'tablemanager')

	const resizeCalc = useCallback(() => {
		if (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent))
			setIsMobile(true)
	}, [])

	const partnerStyleSheetHelper = useCallback(() => {
		const standbyPartnerRegex = /^(gyeongjuro)$/
		if (standbyPartnerRegex.test(partnerName))
			return partnerName
		return 'global'
	}, [partnerName])

	useLayoutEffect(() => {
		resizeCalc()
		if (window.location.origin.includes('kb'))
			return setPartnerName('kb')
		if (window.location.origin.includes('samsung'))
			return setPartnerName('samsung')
		if (window.location.origin.includes('mfg'))
			return setPartnerName('mfg')
		if (window.location.origin.includes('skt'))
			return setPartnerName('skt')
		if (window.location.origin.includes('kt'))
			return setPartnerName('kt')
		if (window.location.origin.includes('shinhan'))
			return setPartnerName('shinhan')
		if (window.location.origin.includes('gyeongjuro'))
			return setPartnerName('gyeongjuro')
		return setPartnerName('tablemanager')
	}, [])

	useLayoutEffect(() => {
		localStorage.setItem('partnerName', partnerName)
		let keys = Object.keys(colorSet[partnerName])
		keys.forEach(key => {
			document.documentElement.style.setProperty(key, colorSet[partnerName][key])
		})
		let themeVariables = colorSet[partnerName]
		if (isMobile)
			themeVariables = { ...themeVariables, '--max-width' : 'none' }
		else
			themeVariables = { ...themeVariables, '--max-width' : '360px' }

		cssVars({
			rootElement:document.documentElement,
			variables: themeVariables
		})
	}, [partnerName, isMobile])

	return (
		<div
			className={classNames(isMobile
				? 'main-container-mobile'
				: 'main-container-desktop', partnerStyleSheetHelper())}
		>
			<Routes></Routes>
		</div>
	)
}

export default App
