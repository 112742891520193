import React, { useEffect, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import '../../../assets/Css/carousel.min.css'
import TheyeyakWithBrands from '../../../Components/Voucher/Home/TheyeyakWithBrands/TheyeyakWithBrands'
import Footer from '../../../Components/Voucher/Footer/Footer'
import { useAccountContext } from '../../../context/Common/account'
import { useLoginContext } from '../../../context/Common/login'
import { useModalContext } from '../../../context/Common/modal'
import { useVoucherContext } from '../../../context/Voucher/voucher'
import { useVoucherContext as useVoucherContextCommon } from '../../../context/Common/voucher'

import {
	getBrands,
	getVoucherProducts,
	getCommonMultimedia,
	getLiveCommerces,
	getFavorites,
	patchFavorite
} from '../../../lib/voucher'
import TrackingAPI from '../../../lib/tracking'
import { favoriteType } from '../../../Components/Voucher/utils'
import './Main.scss'
import referralConfigDict from '../../../configs/referralDict.json'
import { useHomeContext } from '../../../context/Voucher/home'
import MainBanner from '../../../Components/Voucher/Main/MainBanner/MainBanner'
import WishList from '../../../Components/Voucher/Main/WishList/WishList'
import LiveProduct from '../../../Components/Voucher/Main/LiveProduct/LiveProduct'
import SellingVoucher from '../../../Components/Voucher/Main/SellingVoucher/SellingVoucher'
import InquiryBanner from '../../../Components/Voucher/Main/InquiryBanner/InquiryBanner'
import ReviewList from '../../../Components/Voucher/Main/ReviewList/ReviewList'
import MissingLive from '../../../Components/Voucher/Main/MissingLive/MissingLive'
import CustomerInquiry from '../../../Components/Voucher/Main/CustomerInquiry/CustomerInquiry'
import ChannelService from '../../../lib/channelTalk'

const debounce = (fn, delay = 100) => {
	let time
	return (...args) => {
		clearTimeout(time)
		time = setTimeout(() => fn(...args), delay)
	}
}

export default function Main(props) {
	const history = useHistory()
	const { setMainBannerImages, setVouchers, setNowLiveProducts, setMissLiveProducts, myWishList, setMyWishList, myNotificationList, setMyNotificationList, setReviewList, brands, setBrands } = useHomeContext()
	const { voucherMainScrollY, changeVoucherMainScrollY, referralConfig, setReferralConfig } = useVoucherContext()
	const { user } = useAccountContext()
	const { setIsOpenDrawer, setLoginPath, uriReferral, setUriReferral } = useLoginContext()
	const { toastOpen } = useModalContext()
	const trackingAPI = useMemo(() => new TrackingAPI(user, null), [user])
	const { dispatchVoucherInfo } = useVoucherContextCommon()

	useEffect(() => {
		dispatchVoucherInfo({ type: 'CLEAR' })
		ChannelService.loadScript()
		return () => {
			ChannelService.shutdown()
		}
	}, [])

	useEffect(() => {
		if (user) {
			ChannelService.boot({
				'pluginKey': '7de3641c-5ab5-4370-b8d7-d6ab368478b6',
				'memberId': user.phone,
				'profile': {
					'name': user.name,
					'mobileNumber': user.phone,
				}
			})
		} else {
			ChannelService.boot({ 'pluginKey': '7de3641c-5ab5-4370-b8d7-d6ab368478b6', })
		}
	}, [user])
	const setBrandData = () => {
		getBrands().then((res) => {
			const brandData = res.brands
			const brandReviewList = brandData.map(brand => brand.reviews)
			setBrands(brandData)
			setReviewList(brandReviewList.flat())
		}).catch(() => ({}))
	}

	const setLiveCommerces = (status = 'ON_AIR') => {
		getLiveCommerces(status).then(res => {
			if (status === 'ON_AIR')
				setNowLiveProducts(res.liveCommerces)
			else
				setMissLiveProducts(res.liveCommerces.filter(commerce => commerce.liveCommerce.videoLink))
		}).catch(() => console.log)
	}

	const setFavoritesData = (token) => {
		getFavorites(token).then((res) => {
			const userFavoriteVoucherServices = res.userFavoriteVoucherServices
			setMyWishList(userFavoriteVoucherServices.filter(service => service.type === favoriteType.LIKE))
			setMyNotificationList(userFavoriteVoucherServices.filter(service => service.type === favoriteType.NOTIFICATION))
		}).catch(err => console.log)
	}

	const setVoucherProducts = (referral) => {
		getVoucherProducts(referral)
			.then(res => {
				setVouchers(res.products.sort((a, b) => a.service.priority - b.service.priority))
			})
	}

	const setMainBanner = () => {
		if (referralConfig.mainBannerType) {
			getCommonMultimedia(referralConfig.mainBannerType)
				.then(res => {
					setMainBannerImages(res.multimediaFiles)
				})
		}
	}

	const handleScrollY = (e) => {
		const scrollY = window.scrollY
		if (scrollY)
			changeVoucherMainScrollY(scrollY)
	}

	const setData = () => {
		setBrandData()
		setLiveCommerces()
		setLiveCommerces('REPLAYABLE')
		return goScrollY()
	}

	const goScrollY = () => {
		const scrollTimeout = setTimeout(() => {
			const tempScrollY = voucherMainScrollY.toString()
			window.scrollTo(0, parseInt(tempScrollY))
			setTimeout(() => changeVoucherMainScrollY(0))
		}, 300)
		return () => clearTimeout(scrollTimeout)
	}
	const changeScrollY = () => window.addEventListener('scroll', (e) => changeScrollYDebounced(e))
	const changeScrollYDebounced = debounce(handleScrollY)
	useEffect(() => {
		const state = props.location.state
		if (state?.authRequired) {
			let path = state.from?.pathname
			if (state.from?.search)
				path += state.from?.search
			setLoginPath(path)
			setIsOpenDrawer(true)
			history.replace(history.location.pathname)
		}

		if (history.action === 'REPLACE' && history.location.state === 'mallScrollY')
			return setData()
		if (history.location.search) {
			const urlParam = {}
			history.location.search.replace('?', '').split('&').forEach(value => {
				const [key, token] = value.split('=')
				urlParam[key] = token
			})
			if (urlParam.referral) {
				sessionStorage.setItem('referral', urlParam.referral)
				setUriReferral(urlParam.referral)
				history.replace(history.location.pathname)
			}
		}
		changeScrollY()
		if (sessionStorage.getItem('referral') || uriReferral) {
			let referralText = sessionStorage.getItem('referral') || uriReferral
			let configKeys = Object.keys(referralConfigDict)
			if (configKeys.includes(referralText))
				setReferralConfig(referralConfigDict[referralText])
			else
				setReferralConfig(referralConfigDict.default)
		} else {
			setReferralConfig(referralConfigDict.default)
		}

		return setData()
	}, [])

	useEffect(() => {
		if (user && user.token)
			setFavoritesData(user.token)
	}, [user])

	useEffect(() => {
		if (sessionStorage.getItem('referral') || uriReferral) {
			let referralText = sessionStorage.getItem('referral') || uriReferral
			setVoucherProducts(referralText)
			let configKeys = Object.keys(referralConfigDict)
			if (configKeys.includes(referralText))
				setReferralConfig(referralConfigDict[referralText])
			else
				setReferralConfig(referralConfigDict.default)
		} else {
			setVoucherProducts()
		}
	}, [uriReferral])

	useEffect(() => {
		if (referralConfig.mainBannerType)
			setMainBanner()

	}, [referralConfig.mainBannerType])

	useEffect(() => {
		if (history.location.state !== 'mallScrollY')
			return () => { }

		const scrollY = localStorage.getItem('scrollY')
		changeVoucherMainScrollY(parseInt(scrollY))
		return goScrollY()
	}, [voucherMainScrollY])

	const wishIt = (type, serviceId, serviceName) => {
		if (user) {
			const targetList = type === 'LIKE'
				? myWishList
				: myNotificationList
			const enabled = targetList
				? !targetList.some(item => item.service.id === serviceId)
				: true
			const body = {
				type,
				enabled
			}
			patchFavorite(user.token, serviceId, body)
				.then((res) => {
					if (enabled) {
						toastOpen(type === 'LIKE'
							? '❤️ 예약상품권 찜 완료! ❤️'
							: '🛎  판매가 시작되면 카카오톡으로 알려드릴게요  🛎'
						)
					}
					trackingAPI.like(serviceName, enabled)
					setFavoritesData(user.token)
					setVoucherProducts(uriReferral)
				})
		} else {
			setIsOpenDrawer(true)
		}
	}

	const moveInquiryPage = (type) => {
		if (type === 'customer') {
			if (!user)
				history.replace('/voucher/inquiry/customer')
			else
				history.push('/voucher/inquiry/customer')
		}

		if (type === 'partnership')
			history.push('/voucher/inquiry/partnership')
	}

	const goVoucherDetailPage = (brandId) => {
		history.push(`/voucher/detail/${brandId}`, '_blank')
	}

	// TODOS: 메인 배너 클릭시 이동되어야할 곳이 결정되면 수정 필요
	const clickMainBanner = (url) => {
		window.open('')
	}

	return (
		<div className="main-container">
			{
				referralConfig.mainBanner ?
					<MainBanner></MainBanner> :
					null
			}
			{
				referralConfig.nowLive ?
					<LiveProduct></LiveProduct> :
					null
			}
			{
				referralConfig.myWishList ?
					<WishList goVoucherDetailPage={goVoucherDetailPage} trackingAPI={trackingAPI} ></WishList> :
					null
			}

			<SellingVoucher wishIt={wishIt} trackingAPI={trackingAPI}></SellingVoucher>

			{
				<KaKaoAdBanner></KaKaoAdBanner>
			}

			{
				referralConfig.review ?
					<ReviewList></ReviewList>
					: null
			}

			{/* {
				vouchers && getVouchers('pre').length > 0 ?
					<div className="voucher-products">
						<Title text={'판매예정 예약상품권 ✨'}></Title>
						<VoucherProductList type="card" data={getVouchers('pre')} customFunction={wishIt} favoriteList={myNotificationList}></VoucherProductList>
					</div>
					: <></>
			} */}

			{
				referralConfig.missingLive ?
					<MissingLive></MissingLive> :
					null
			}

			{
				brands && brands.length > 0 && referralConfig.withBrands ?
					<TheyeyakWithBrands brands={brands} /> :
					null
			}

			{
				referralConfig.customerInquiry ?
					<CustomerInquiry moveInquiryPage={moveInquiryPage}></CustomerInquiry> :
					null
			}
			<Footer />
		</div>
	)
}


const KaKaoAdBanner = () => {
	useEffect(() => {
		const script = document.createElement('script')
		script.src = '//t1.daumcdn.net/kas/static/ba.min.js'
		script.async = true
		document.body.appendChild(script)

		const checkAdLoad = () => {
			const adElement = document.querySelector('.kakao_ad_area')
			if (adElement)
				adElement.style.display = 'block'
		}

		// 1초 후 광고 배너 표시 시도
		setTimeout(checkAdLoad, 1000)

		return () => {
			document.body.removeChild(script)
		}
	}, [])

	return (
		<ins
			className="kakao_ad_area"
			style={{ width: '100%', height: '100%', display: 'none', marginBottom: '30px', marginTop: '20px', }}
			data-ad-unit="DAN-qhAnlaZPz1yKKwXr"
		></ins>
	)
}
