import React from 'react'
import { useHomeContext } from '../../../../context/Voucher/home'

export default function MainBanner() {
	const { mainBannerImages } = useHomeContext()
	if (!mainBannerImages || mainBannerImages.length === 0)
		return null
	const moveLink = (image) => {
		if (image.description.startsWith('https'))
			window.open(image.description, 'Ex')
	}
	return (
		<div className="main-banner-wrapper">
			{/* <div className="carousel-wrapper" style={mobileWidthResizing()}>
					<Carousel
						className="carousel-container"
						showStatus={false}
						showThumbs={false}
						swipeable={true}
						emulateTouch={true}
						showArrows={false}
						autoPlay={false}
						showIndicators={false}
						centerMode={true}
						centerSlidePercentage={100}
						infiniteLoop={true}
						interval={10000000000}
					>
						{mainBannerImages.map((image, index) => <img className="main-banner-image" key={index} src={image.fileLink} alt=""></img>)}
					</Carousel>
				</div> */}
			{mainBannerImages.map((image, index) => <img className="main-banner-image" key={index} src={image.fileLink} alt="" onClick={() => moveLink(image)}></img>)}
		</div>
	)
}
