/* eslint-disable camelcase */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { BookingHomeAPI } from '../../../lib/mytable'
import { HomeContextProvider } from '../../../context/Booking/home'
import { PartnerAPI } from '../../../lib/partner'
import ShinhanModal from '../../../Components/Share/ShinhanModal/ShinhanModal'
import { getVoucherProducts } from '../../../lib/voucher'
import { usePartnerContext } from '../../../context/Common/partner'
import { useMall, useModify, useReservation, useValidation } from '../../../store'
import { Banner, FindRestaurantButton, Footer, Location, Recommend, VoucherMalls } from '../../../Components/Booking'

export default function Home() {
	const { apiKey, setPartnerInfo, partnerName } = usePartnerContext()
	const location = useLocation()
	const history = useHistory()
	const [banners, setBanners] = useState(null)
	const [regions, setRegions] = useState(null)
	const [recommendMalls, setRecommendMalls] = useState(null)
	const [brands, setBrands] = useState(null)
	const { malls, setMall } = useMall()
	const { resetReservation } = useReservation()
	const { resetModify } = useModify()
	const { resetValidation } = useValidation()
	const bookingHomeAPI = useMemo(() => new BookingHomeAPI(apiKey, null), [apiKey])
	const search = location.search
	const chnlMbrId = new URLSearchParams(search).get('chnlMbrId')
	const chnlId = new URLSearchParams(search).get('chnlId')
	const toRedirect = new URLSearchParams(search).get('redirect')
	const loginParam = sessionStorage.getItem('tParam') || new URLSearchParams(search).get('tParam')
	const partnerAPI = new PartnerAPI(apiKey, null)

	useEffect(() => {
		resetReservation()
		resetModify()
		resetValidation()
	}, [])

	useEffect(() => {
		if (toRedirect) {
			history.replace('/booking/main')
			history.push('/booking/list')
		}
	}, [toRedirect])
	useEffect(() => {
		if (loginParam)
			sessionStorage.setItem('tParam', loginParam)
		if (apiKey) {
			getBanners()
			getMalls()
			getRegions()
			if (loginParam && partnerName === 'skt') {
				setTimeout(() => {
					partnerAPI.sktLogin(loginParam).then(res => {
						sessionStorage.setItem('authorization', res.authorization)
						setPartnerInfo({
							name: res.name,
							phone: res.phone,
							email: res.email
						})
						sessionStorage.setItem('referral', 'sktvip')
					})
				}, 100)
			}
			if (loginParam && partnerName === 'shinhan') {
				// authorization 이 있을 때 중복으로 체크하지 않고, 리턴 시켜준다.
				if (sessionStorage.getItem('authorization'))
					return
				setTimeout(() => {
					partnerAPI.shinhanLogin(loginParam).then(res => {
						sessionStorage.setItem('authorization', res.authorization)
						setPartnerInfo({
							name: res.name,
							phone: res.phone,
						})
						sessionStorage.setItem('referral', 'shinhanplay')
					})
				}, 100)
			}
		}

	}, [apiKey])
	useEffect(() => {
		if (chnlMbrId && chnlId) {
			setPartnerInfo({
				chnlMbrId,
				chnlId
			})
		}
	}, [])

	useEffect(() => {
		return () => {
			if (history.action === 'POP')
				window.close()
		}
	}, [history])

	useEffect(() => {
		if (partnerName === 'skt' || partnerName === 'shinhan')
			getVoucherMalls()
	}, [partnerName])
	function getBanners () {
		bookingHomeAPI.getPartnerBanner().then(res => {
			const mappedBanners = res.list.map(banner=> {
				return {
					imageUrl: banner.img_url,
					type: banner.url !== '#'
						? banner.url.split(',')[0].replace('@', '')
						: '',
					value: banner.url !== '#'
						? banner.url.split(',')[1]
						: ''
				}
			})
			setBanners(mappedBanners)
		})
	}

	function getMalls() {
		bookingHomeAPI.getMalls().then(res => {
			let recommend = res.list.filter((item) => item.mall_group['추천매장'])
			recommend = recommend.sort((a, b) => {
				if (Number(a.mall_group['추천매장']) < Number(b.mall_group['추천매장']))
					return -1
				else if (Number(b.mall_group['추천매장']) < Number(a.mall_group['추천매장']))
					return 1
				return 0
			})
			recommend.forEach((mall) => {
				mall.hashtags = []
				if (mall.mall_group['해시태그'])
					mall.hashtags = mall.mall_group['해시태그'].split(',')
			})

			res.list = res.list.sort((a, b) => {
				if (a.mall_name < b.mall_name)
					return -1
				else if (b.mall_name < a.mall_name)
					return 1
				return 0
			}).sort((a, b) => {
				if (a.mall_group['할인율'] < b.mall_group['할인율'])
					return 1
				else if (b.mall_group['할인율'] < a.mall_group['할인율'])
					return -1
				return 0
			})
			goLastScrollPosition()
			setRecommendMalls(recommend)
			setMall('malls', res.list)
		})
	}

	function goLastScrollPosition() {
		const moveMallId = sessionStorage.getItem('moveMallId')
		const moveScrollY = sessionStorage.getItem('moveScrollY')
		if (!moveMallId && !moveScrollY)
			return
		if (!moveMallId && moveScrollY) {
			let dragDocument = document.getElementById('drag-voucher')
			if (!dragDocument) {
				setTimeout(goLastScrollPosition, 100)
				return
			}
			window.scrollTo(0, Number(sessionStorage.getItem('moveScrollY')))
			sessionStorage.removeItem('moveScrollY')
			return
		}

		let targetDocument = document.getElementById('mall-item-' + moveMallId)
		if (!targetDocument) {
			setTimeout(goLastScrollPosition, 100)
			return
		}
		window.scrollTo(0, Number(sessionStorage.getItem('moveScrollY')))
		sessionStorage.removeItem('moveMallId')
		sessionStorage.removeItem('moveScrollY')
	}

	function getRegions() {
		bookingHomeAPI.getRegions().then(res => {
			let regionsList = res.list
			regionsList = regionsList.sort((a, b) => {
				return a.regions_priority - b.regions_priority
			})
			regionsList.splice(0, 0, { regions_name: '모든지역', })
			setRegions(regionsList)
		})
	}

	function getVoucherMalls() {
		let referralName = partnerName
		if (referralName === 'skt')
			referralName = 'sktvip'
		if (referralName === 'shinhan')
			referralName = 'shinhanplay'
		getVoucherProducts(referralName).then(res => {
			let tmpVoucher = res.products.sort((a, b) => a.service.priority - b.service.priority)
			setBrands(tmpVoucher)
		})
	}

	if (!malls || !banners || !recommendMalls)
		return null

	return (
		<HomeContextProvider malls={malls} propBanners={banners} propRegions={regions} propRecommendMalls={recommendMalls} propBrands={brands}>
			{partnerName === 'shinhan' && <ShinhanModal />}
			<div>
				<Banner></Banner>
				<FindRestaurantButton></FindRestaurantButton>
				{partnerName === 'skt' || partnerName === 'shinhan'
					? <VoucherMalls></VoucherMalls>
					: null}
				<Recommend></Recommend>
				<Location></Location>
			</div>
			<Footer></Footer>
		</HomeContextProvider>
	)
}
