import React, { useLayoutEffect } from 'react'
import InputHint from '../../../Share/InputHint'
import BookingSubtitle from '../../../Share/Booking/BookingSubtitle/BookingSubtitle'
import { Button } from '@material-ui/core'
import { usePartnerContext } from '../../../../context/Common/partner'
import { useMemo } from 'react'
import { BookingMallDetailAPI } from '../../../../lib/mytable'
import { useState } from 'react'
import { useModalContext } from '../../../../context/Common/modal'
import { ReactComponent as Clear } from '../../../../assets/icon/clear.svg'
import { authorizationWithPrefix } from '../../../../lib/utils'
import {
	useProvisionalReservation,
	useReservation,
	useTimer,
	useValidation,
} from '../../../../store'
import classNames from 'classnames'
import moment from 'moment'
import Format from '../../../../utils/Format'
import useDynamicComponent from '../../../../hooks/useDynamicComponent'

const PHONE_AUTH_STATUS = {
	init: 'initialState',
	process: ' authProcessing',
	complete: 'authCompleted',
}

export default function UserInfoForm() {
	const { apiKey } = usePartnerContext()
	const { alertOpen } = useModalContext()
	const { voucherData, name, phone, email, setReservation } = useReservation()
	const { validateFormData, setValidation, ...pass } = useValidation()
	const {
		reservationToken,
		authToken,
		authCode,
		setProvisionalReservation,
		resetAuth,
	} = useProvisionalReservation()
	const { setTimer } = useTimer()
	const { renderComponent } = useDynamicComponent()
	const [dirty, setDirty] = useState({
		name: false,
		phone: false,
		phoneAuth: true,
		email: false,
	})
	const [phoneAuthStatus, setPhoneAuthStatus] = useState(
		PHONE_AUTH_STATUS.init
	)
	const bookingMallDetailAPI = useMemo(
		() => new BookingMallDetailAPI(apiKey, authorizationWithPrefix()),
		[apiKey, voucherData]
	)

	const requestPhoneAuthCode = () => {
		if (phoneAuthStatus === PHONE_AUTH_STATUS.init)
			return sendPhoneAuthCode()
		return resetPhoneAuth()
	}

	const sendPhoneAuthCode = () => {
		if (pass.phone && !authToken && phone) {
			bookingMallDetailAPI
				.checkPhoneAuth(phone.replace(/-/g, ''), reservationToken)
				.then((res) => {
					if (res.result) {
						if (res.isPhoneAuth) {
							alertOpen('인증번호가 발송되었습니다.')
							setProvisionalReservation('authToken', res.token)
							setValidation('phoneAuth', '휴대폰 인증을 진행해주세요.')
							setTimer('expire', moment(res.date_expire))
							setPhoneAuthStatus(PHONE_AUTH_STATUS.process)
						} else {
							setValidation('phoneAuth', true)
						}
						return
					}
					alertOpen(res.message)
				})
				.catch((e) => {
					alertOpen(e.message)
				})
		}
	}

	const phoneAuth = () =>
		bookingMallDetailAPI
			.phoneAuth(authCode, authToken, reservationToken)
			.then((res) => {
				if (res.result) {
					alertOpen('휴대폰 인증이 완료되었습니다.')
					setValidation('phoneAuth', true)
				} else {
					alertOpen(res.message)
				}
			})
			.catch((e) => {
				alertOpen(e.message)
			})

	const resetPhoneAuth = () => {
		setValidation('phoneAuth', false)
		resetAuth()
	}

	const sendParentEvent = (e) => {
		window.parent.postMessage({ func: 'hideKeyPad' }, '*')
		setDirty({ ...dirty, [e.target.name]: true })
		validateFormData(e)
	}

	const hideErrorWhenFocus = (e) =>
		setDirty({ ...dirty, [e.target.name]: false })

	const triggerEventWhenClear = (type) => {
		const inputElement = document.getElementsByName(type)[0]
		Object.getOwnPropertyDescriptor(
			window.HTMLInputElement.prototype,
			'value'
		).set.call(inputElement, '')
		inputElement.dispatchEvent(new InputEvent('input', { bubbles: true }))
		inputElement.focus()
	}

	const saveFormData = (e) => {
		const { name: eName, value: eVal } = e.target

		switch (eName) {
		case 'name':
			setReservation(eName, eVal.replace(/[^a-z|A-Z|ㄱ-ㅎ|가-힣|ㆍᆢ]/g, ''))
			break

		case 'phone':
			if (eVal.length > 13)
				return
			setReservation(eName, Format.phone(eVal))
			break

		case 'email':
		default:
			setReservation(eName, eVal)
			break
		}
	}

	useLayoutEffect(
		() =>
			setPhoneAuthStatus(
				pass.phoneAuth === true
					? PHONE_AUTH_STATUS.complete
					: PHONE_AUTH_STATUS.init
			),
		[pass.phoneAuth]
	)

	return (
		<div className="booking-user-info-form-container">
			{renderComponent({
				default: <BookingSubtitle subtitle="예약자 정보"></BookingSubtitle>,
				gyeongjuro: (
					<BookingSubtitle subtitle="예약자 정보" mini></BookingSubtitle>
				),
			})}
			<div className="input-label b3">
				이름<span className="firebrick">*</span>
			</div>
			<div className="input-box">
				<input
					type="text"
					className={classNames('input b1', { error: typeof pass.name === 'string' && dirty.name, })}
					placeholder="성명 입력"
					name="name"
					value={name || ''}
					onBlur={(e) => sendParentEvent(e)}
					onFocus={(e) => hideErrorWhenFocus(e)}
					onChange={(e) => saveFormData(e)}
				/>
				<div
					className="clear-button"
					onClick={() => triggerEventWhenClear('name')}
					onMouseDown={(e) => e.preventDefault()}
				>
					<Clear className="icon" />
				</div>
				{typeof pass.name === 'string' && dirty.name && (
					<InputHint text={pass.name} />
				)}
			</div>
			<div className="input-label b3">
				휴대폰 번호<span className="firebrick">*</span>
			</div>
			{phoneAuthStatus !== PHONE_AUTH_STATUS.complete
				? (
					<>
						<div className="input-box">
							<div className="phone-auth-box">
								<input
									name="phone"
									type="text"
									className={classNames('input auth-number b1', { error: typeof pass.phone === 'string' && dirty.phone, })}
									disabled={authToken}
									maxLength="13"
									inputMode="numeric"
									onBlur={(e) => sendParentEvent(e)}
									onFocus={(e) => hideErrorWhenFocus(e)}
									placeholder="010-0000-0000"
									value={phone || ''}
									onChange={(e) => saveFormData(e)}
								/>
								{renderComponent({
									default: (
										<Button
											variant="contained"
											color="primary"
											className="auth-button"
											onClick={requestPhoneAuthCode}
										>
											{phoneAuthStatus === PHONE_AUTH_STATUS.process
												? '다시 인증'
												: '인증번호 요청'}
										</Button>
									),
									gyeongjuro: (
										<button className="auth-button" onClick={requestPhoneAuthCode}>
											{phoneAuthStatus === PHONE_AUTH_STATUS.process
												? '다시 인증'
												: '인증요청'}
										</button>
									),
								})}
							</div>
							{typeof pass.phone === 'string' && dirty.phone && (
								<InputHint text={pass.phone} />
							)}
						</div>
						<div className="input-label b3">인증번호</div>
						<div className="input-box">
							<div className='phone-auth-box'>
								<input
									name="phoneAuth"
									type="number"
									className={classNames('input auth-number b1', { error: typeof pass.phoneAuth === 'string' && dirty.phoneAuth, })}
									placeholder="인증번호 입력"
									disabled={!authToken}
									value={authCode || ''}
									onBlur={(e) => sendParentEvent(e)}
									onFocus={(e) => hideErrorWhenFocus(e)}
									onChange={(e) =>
										setProvisionalReservation(
											'authCode',
											e.target.value.replace(/[^0-9]/, '')
										)
									}
								/>
								{renderComponent({
									default: (
										<Button
											disabled={!authToken}
											variant="contained"
											color="primary"
											className="auth-button"
											onClick={phoneAuth}
											onMouseDown={(e) => e.preventDefault()}
										>
									확인
										</Button>
									),
									gyeongjuro: (
										<button
											className="auth-button"
											disabled={!authToken}
											onClick={phoneAuth}
											onMouseDown={(e) => e.preventDefault()}
										>
									번호입력
										</button>
									),
								})}
							</div>
							{typeof pass.phoneAuth === 'string' && dirty.phoneAuth && (
								<InputHint text={pass.phoneAuth} />
							)}
						</div>
					</>
				)
				: (
					<div className="input-box">
						<input
							name="phone"
							type="text"
							className="input b1"
							disabled={authToken || pass.phoneAuth === true}
							maxLength="13"
							inputMode="numeric"
							onBlur={(e) => sendParentEvent(e)}
							onFocus={(e) => hideErrorWhenFocus(e)}
							placeholder="010-0000-0000"
							value={phone}
							onChange={(e) => saveFormData(e)}
						></input>
						{renderComponent({
							default: (
								<div className="phone-change-button b1" onClick={resetPhoneAuth}>
								변경
								</div>
							),
							gyeongjuro: (
								<button className="auth-button" onClick={resetPhoneAuth}>
								변경
								</button>
							),
						})}
					</div>
				)}
			<div className="input-label b3">이메일</div>
			<div className="input-box email">
				<input
					name="email"
					type="email"
					className="input b1"
					placeholder="이메일을 입력해주세요."
					value={email || ''}
					onBlur={(e) => sendParentEvent(e)}
					onFocus={(e) => hideErrorWhenFocus(e)}
					onChange={(e) => saveFormData(e)}
				/>
				<div
					className="clear-button"
					onClick={() => triggerEventWhenClear('email')}
					onMouseDown={(e) => e.preventDefault()}
				>
					<Clear className="icon" />
				</div>
				{typeof pass.email === 'string' && dirty.email && (
					<InputHint text={pass.email} />
				)}
			</div>
			<div className="form-description b3">
				이메일 주소 입력하시면 결제 내역을 발송해드립니다.
			</div>
		</div>
	)
}
